export default {
  BUTTON_PRIMARY_CLASS: 'btn btn-default',
  FACET_DIV_CLASS: "{this.field === 'Category' && this.term === 'Clearance' ? 'clearance' : ''}",
  LOAD_MORE_BUTTON_CLASS: '',
  MIN_PRICE: 'Min',
  MAX_PRICE: 'Max',
  PRODUCT_REPEATER_CLASS: 'product-items',
  FACET_DIALOG_TITLE: 'Filter Options',
  SELECT_TITLE: 'Select {{ title }}',

  SEARCH_BOX_BUTTON_CLASS: 'btn btn-default',
};
