import { getIsolatedKeyFromWidgetName, isolatedHeader } from 'Addons/fitmentSearch/isolatedKeys.ts';
import { mainConfig } from 'Models/uiConfig/componentlessWidgetsConfig.ts';
import defaultFitmentSearchConfig from 'Stores/_default-store/fitmentSearchConfig.js';
import { trimEndSlash } from 'Utils/string.js';
import requestConfig from './requestConfig.js';

const {
  fitmentSearch: {
    baseFields = [],
    categorySelectionPageRedirect,
    categorySelectionPages,
    disableServerRedirects,
    doNotSaveSelectedVehicle,
    extraFields = [],
    isExtraFieldsAlwaysVisible = false,
    isVehicleSelectionIsolated = false,
    forceSpecifyDialog,
    forceVehicleSelection = false,
    forceVehicleSelectionInterval = 60, // one hour
    getFitmentSearchTitle = () => 'Parts',
    isAutoRedirectDisabled,
    isAutoVehicleSelectionDisabled,
    hideVehicleFromUrl = false,
    onVehicleDiscarded: customOnVehicleDiscarded,
    onVehicleSelected: customOnVehicleSelected,
    onVerifyFitmentInitResponseReceived: customOnVerifyFitmentInitResponseReceived,
    onVerifyFitmentResponseReceived: customOnVerifyFitmentResponseReceived,
    hideVerifyOnCartItems = false,
    vehicleDataExpires,
  } = {},
  Widgets,
} = mainConfig;

const fitmentFields = [...baseFields, ...extraFields];

const categorySelectionPage = Array.isArray(categorySelectionPages)
  ? categorySelectionPages[0]
  : categorySelectionPages;
const { pathname: categorySelectionPageUrl, field: categorySelectionPageField } = categorySelectionPage || {};

const currentCategorySelectionPageField =
  (categorySelectionPages &&
    []
      .concat(categorySelectionPages)
      .find((page) => trimEndSlash(window.location.pathname) === trimEndSlash(page.pathname))?.field) ||
  null;
const isOnCategorySelectionPage = !!currentCategorySelectionPageField;

const isFacetTilesVehicleWidgetEnabled = Widgets.some(
  (w) =>
    w.visible && w.type === 'FacetTiles' && !requestConfig.hasSearchResults && !w.isVehicleWidgetDisabled,
);

const isFitmentSearchWidgetsVisible =
  isFacetTilesVehicleWidgetEnabled ||
  Widgets.some((w) => {
    const [nameRoot] = w.name.split('_');
    return (
      nameRoot !== 'DialogVehicleWidget' &&
      w.visible &&
      (w.type === 'VehicleTabs' ||
        w.type === 'VehicleWidget' ||
        w.type === 'VehicleTiles' ||
        w.type === 'VerifyFitment')
    );
  });

const needInitFitmentSearchRequest =
  isFacetTilesVehicleWidgetEnabled ||
  Widgets.map((w) => w.visible && getIsolatedKeyFromWidgetName(w.name)).includes(isolatedHeader);
const needInitFitmentTableRequest = Widgets.some((w) => w.visible && w.type === 'FitmentTable');
const needInitVerifyFitmentRequest = Widgets.some((w) => w.visible && w.type === 'VerifyFitment');

const defaults = defaultFitmentSearchConfig || {
  onVehicleSelected: null,
  onVehicleDiscarded: null,
  onVerifyFitmentInitResponseReceived: null,
  onVerifyFitmentResponseReceived: null,
};

const onVehicleSelected = (vehicle) => {
  defaults.onVehicleSelected?.(vehicle);
  customOnVehicleSelected?.(vehicle);
  window.Convermax.onVehicleSelected?.(vehicle);
};

const onVehicleDiscarded = () => {
  defaults.onVehicleDiscarded?.();
  customOnVehicleDiscarded?.();
};

const onVerifyFitmentInitResponseReceived = (response) => {
  defaults.onVerifyFitmentInitResponseReceived?.(response);
  customOnVerifyFitmentInitResponseReceived?.(response);
};

const onVerifyFitmentResponseReceived = (response) => {
  defaults.onVerifyFitmentResponseReceived?.(response);
  customOnVerifyFitmentResponseReceived?.(response);
};

const fitmentSearchConfig = {
  baseFields,
  categorySelectionPageField,
  categorySelectionPageUrl,
  currentCategorySelectionPageField,
  disableServerRedirects,
  doNotSaveSelectedVehicle,
  extraFields,
  fields: fitmentFields,
  fitmentSearchTitle: getFitmentSearchTitle(),
  forceSpecifyDialog,
  forceVehicleSelection,
  forceVehicleSelectionInterval,
  hideVehicleFromUrl,
  garageDetected: Widgets.some((w) => w.type === 'Garage'),
  isCategorySelectionPageRedirectEnabled: categorySelectionPageRedirect,
  isExtraFieldsAlwaysVisible,
  isOnCategorySelectionPage,
  isAutoRedirectDisabled,
  isAutoVehicleSelectionDisabled,
  isFitmentSearchWidgetsVisible,
  isVehicleSelectionIsolated,
  isVehicleWidgetInPartialMode: Widgets.some(
    (w) => (w.type === 'VehicleWidget' && w.fields) || w.type === 'VehicleTabs',
  ),
  needInitFitmentSearchRequest,
  needInitFitmentTableRequest,
  needInitVerifyFitmentRequest,
  onVehicleDiscarded,
  onVehicleSelected,
  onVerifyFitmentInitResponseReceived,
  onVerifyFitmentResponseReceived,
  hideVerifyOnCartItems,
  vehicleDataExpires,
};

window.Convermax.templates.config.fitmentSearchConfig = fitmentSearchConfig;
export default fitmentSearchConfig;
